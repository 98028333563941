import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import Switch from "react-switch";
import { Accordion } from 'react-bootstrap';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import SchedulerSocialForm from '../../component/section/schedulers/schedule_social_form';
import Loading from '../../component/alerts/Loading';
import SchedulerForm from '../../component/section/schedulers/schedule_form';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';
import ScheduleNavbar from '../../component/section/schedulers/schedule_navbar';
import GameTitleForm from '../../component/section/titles/title_form';

const SchedulerUpdatePage = () => {
    const [scheduler, setScheduler] = useState({});
    const [title, setTitle] = useState({});
    const [copySuccess, setCopySuccess] = useState('');
    const { id } = useParams();
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [titleErrors, setTitleErrors] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [communities, setCommunities] = useState([]);
    const [gameMainImageBlob, setMainImageBlob] = useState(null);
    const [gameBannerImageBlob, setBannerImageBlob] = useState(null);

    const navigate = useNavigate();

    const totalSteps = 2;

    const stepNames = [
        "Scheduler Info",
        "Game Info",
    ];

    useEffect(() => {

        if (!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Communities.list({ roles: [Glitch.constants.Roles.ADMINISTRATOR, Glitch.constants.Roles.SUPER_ADMINISTRATOR, Glitch.constants.Roles.MODERATOR], order_by: 'name' }).then(response => {
            setCommunities(response.data.data)
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Scheduler.getSchedule(id).then(response => {
            setScheduler(response.data.data);

            Glitch.api.Titles.view(response.data.data.title_id).then(response => {
                setTitle(response.data.data);
            }).catch(error => {

            })

            if (response.data.data?.title?.community_id) {
                Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data?.title?.community_id).then(response => {
                    setSubscriptions(response.data.data);
                }).catch(error => {
                    console.error(error);
                });
            }
        }).catch(error => {
            console.error(error);
        });
    }, [id]);

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const nextStep = () => {
        setCurrentStep(currentStep => Math.min(currentStep + 1, totalSteps));
        window.scrollTo({
            top: document.documentElement.scrollHeight * 0.1,
            behavior: 'smooth'
        });
    };

    const prevStep = () => {
        setCurrentStep(currentStep => Math.max(currentStep - 1, 1));
        window.scrollTo({
            top: document.documentElement.scrollHeight * 0.1,
            behavior: 'smooth'
        });
    };

    const goToStep = (stepNumber) => {

        setCurrentStep(stepNumber);
    };

    const update = async () => {

        setIsLoading(true);

        let success = true;

        try {
            const schdulerResponse = await Glitch.api.Scheduler.updateSchedule(scheduler.id, scheduler);
            setScheduler(schdulerResponse.data.data);
        } catch(error) {
            success = false;
            let jsonErrors = error?.response?.data;

            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout);
            }
        }

        try {
            const titlerResponse = await Glitch.api.Titles.update(title.id, title);
            setTitle(titlerResponse.data.data);
        } catch(error) {

            success = false;
            let jsonErrors = error?.response?.data;

            if (jsonErrors) {
                setTitleErrors(jsonErrors);
                setTimeout(() => {
                    setTitleErrors({});
                }, timeouts.error_message_timeout);
            }
        }

        if(success) {
            // Upload images
            const promises = [];
            if (gameMainImageBlob) {
                promises.push(Glitch.api.Titles.uploadMainImageBlob(title.id, gameMainImageBlob));
            }
            if (gameBannerImageBlob) {
                promises.push(Glitch.api.Titles.uploadBannerImageFile(title.id, gameBannerImageBlob));
            }
    
            // Wait for all uploads to complete before proceeding
            await Promise.all(promises);
        }
       


        setIsLoading(false);

        if(success) {
            navigate(Navigate.schedulerViewPage(scheduler.id));
        }
    };


    return (
        <>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Schedulers', link: Navigate.schedulerListPage() },
                    { name: scheduler?.name, link: Navigate.schedulerViewPage(scheduler?.id) },
                    { name: "Update Scheduler", link: Navigate.schedulerUpdatePage(scheduler?.id) }]}
                />
            </div>

            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">Update Scheduler</h2>
                        <p className="lead">Update the scheduler.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                <ScheduleNavbar schedulerId={id} />
            </div>

            <div className="container">

               

                <ul className="nav nav-pills mb-3 inline" id="pills-tab" role="tablist">
                    <li className="nav-item pt-1" key={3}>
                        <strong>Change Section:</strong>
                    </li>
                    {stepNames.map((name, index) => (
                        <li className="nav-item" key={index}>
                            <button
                                className={`nav-link ${currentStep === index + 1 ? 'active' : ''}`}
                                id={`pills-${name.toLowerCase()}-tab`}
                                data-toggle="pill"
                                role="tab"
                                aria-controls={`pills-${name.toLowerCase()}`}
                                aria-selected={currentStep === index + 1 ? 'true' : 'false'}
                                onClick={() => goToStep(index + 1)}
                                name={"goStep" + (index + 1)}
                            >
                                {name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>


            {currentStep === 1 && <SchedulerForm schedulerData={scheduler} setSchedulerData={setScheduler} isEditMode={true} errors={errors} />}
            {currentStep === 2 && <>
                <GameTitleForm gameTitle={title} schedulerData={scheduler} setGameTitle={setTitle} setMainImageBlob={setMainImageBlob} setBannerImageBlob={setBannerImageBlob} errors={titleErrors} isCreate={false} communities={communities} requireCommunity={true} />
            </>}

            <div className="mt-2 text-center">
                {Object.keys(errors).length > 0 &&
                    Object.keys(errors).map((errorKey) => {
                        const errorValue = errors[errorKey];

                        // Check if the error value is an array
                        if (Array.isArray(errorValue)) {
                            return errorValue.map((message, index) => (
                                <Danger key={`${errorKey}-${index}`} message={message} />
                            ));
                        }

                        // If it's not an array (e.g., a string), render it directly
                        return (
                            <Danger key={errorKey} message={errorValue} />
                        );
                    })}

                {Object.keys(titleErrors).length > 0 &&
                    Object.keys(titleErrors).map((errorKey) => {
                        const errorValue = titleErrors[errorKey];

                        // Ensure errorValue is treated as an array (wrap in array if not already one)
                        const messages = Array.isArray(errorValue) ? errorValue : [errorValue];

                        return messages.map((message, index) => (
                            <Danger key={`title-${errorKey}-${index}`} message={`Game Title Error: ${message}`} />
                        ));
                    })}
            </div>

            <div className='container text-center mb-5'>
                <button type="button" name='updateScheduler' onClick={update} className="btn btn-primary btn-lg">{isLoading ? <Loading /> : 'Update Scheduler'}</button>

            </div>

            <br /><br />

            <Footer />
        </>
    );
};

export default SchedulerUpdatePage;
